export const numberFormat = (
	number: number,
	options?: Intl.NumberFormatOptions
) => {
	if (number == null) {
		return null;
	}
	return new Intl.NumberFormat(navigator.language, options).format(number);
};

export const dateFormat = (
	value: string,
	options?: Intl.DateTimeFormatOptions
) => {
	if (value == null) {
		return null;
	}
	const date = new Date(value);
	return Intl.DateTimeFormat(navigator.language, options).format(date);
};
export const durationFormat = (number: number) => {
	if (number == null) {
		return null;
	}

	const hour = Math.ceil(number / 60);
	const min = number % 60;

	return `${hour > 0 ? `${hour}h` : ""}${min > 0 ? `${min}min` : ""}`;
};

export const getDefaultLanguage = (options: string[], _default: string) => {
	const lang = navigator.language.split(/[-_]/)[0];
	return options.includes(lang) ? lang : _default;
};
