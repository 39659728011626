import React from "react";
import FilteredNavPanel from "../components/FilteredNavPanel";
import useTranslate from "../hooks/useTranslate";

const Accommodation = () => {
	const translate = useTranslate();

	const options = [
		{
			label: translate("accommodation.choice.hotels", "Hotels"),
			value: "subtype='accommodation' AND tags LIKE '%hotel%'",
		},
		{
			label: translate("accommodation.choice.campsites", "Campsites"),
			value: "subtype='accommodation' AND tags LIKE '%camping%'",
		},
		{
			label: translate("accommodation.choice.guesthouse", "Guesthouses"),
			value: "subtype='accommodation' AND tags LIKE '%guest_house%'",
		},
	];

	return (
		<FilteredNavPanel
			options={options}
			defaultCqlFilter="subtype='accommodation'"
		/>
	);
};

export default Accommodation;
