import React, { HTMLAttributes, useEffect, useState } from "react";
import useWFSLayer from "../hooks/useWFSLayer";
import Checkboxes from "./Checkboxes";
import ResultList from "./ResultList";

type FilteredNavPanelProps = {
	options: { label: string; value: string }[];
	defaultCqlFilter?: string;
} & HTMLAttributes<HTMLDivElement>;

const FilteredNavPanel = ({
	options,
	defaultCqlFilter,
}: FilteredNavPanelProps) => {
	const initialCqlFilter = defaultCqlFilter
		? defaultCqlFilter
		: `(${options.map((opt) => opt.value).join(") OR (")})`;

	const [cqlFilter, setCqlFilter] = useState<string>(initialCqlFilter);
	const [selected, setSelected] = useState<string[]>([]);
	const { features, loading, setHighlighted } = useWFSLayer({
		cqlFilter,
		// limit: 500,
	});

	useEffect(() => {
		if (selected.length > 0) {
			setCqlFilter(selected.join(" OR "));
		} else {
			setCqlFilter(initialCqlFilter);
		}
	}, [selected, initialCqlFilter]);

	return (
		<div className="nav-panel flex flex-col">
			<Checkboxes
				className="m-3"
				options={options}
				value={selected}
				onChange={setSelected}
			></Checkboxes>

			{(loading || features.length > 0) && (
				<ResultList
					className="p-3 pt-0"
					loading={loading}
					features={features}
					setHighlighted={setHighlighted}
				/>
			)}

			{/*{keywords && !loading && features.length == 0 && (
				<div className="p-3 pt-0">
					Il n'y a aucun résultat pour la recherche <strong>{keywords}</strong>
				</div>
			)}*/}
		</div>
	);
};

export default FilteredNavPanel;
