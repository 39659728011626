import {
	MdCalendarMonth,
	MdOutlineDirectionsBike,
	MdOutlineHiking,
	MdRestaurant,
	MdSearch,
	MdStar,
} from "react-icons/md";
import { FaBed } from "react-icons/fa6";
import React from "react";
import { IconType } from "react-icons";
import { NavLink, useSearchParams } from "react-router-dom";

type NavbarItemProps = {
	icon: IconType;
	active?: boolean;
};

const NavItem = ({ icon: Icon, active }: NavbarItemProps) => {
	return (
		<Icon
			className={`w-12 h-12 p-2 cursor-pointer  ${
				active ? "fill-neutral-800 bg-white" : "fill-white hover:bg-neutral-700"
			}`}
		/>
	);
};

const NavLinkItem = ({ to, ...props }: NavbarItemProps & { to: string }) => {
	const [searchParams] = useSearchParams();
	const filteredParams = Array.from(searchParams.entries()).filter(
		([key]) => key === "c" || key === "z"
	);
	const location = {
		pathname: to,
		search: new URLSearchParams(filteredParams).toString(),
	};
	return (
		<NavLink to={location}>
			{({ isActive, isPending }) => <NavItem {...props} active={isActive} />}
		</NavLink>
	);
};

const Navigation = () => {
	return (
		<nav className="relative z-[1002] bg-neutral-800 flex gap-1 flex-col shadow-md">
			<NavLinkItem icon={MdStar} to="featured" />
			<NavLinkItem icon={MdOutlineHiking} to="hiking" />
			<NavLinkItem icon={MdOutlineDirectionsBike} to="cycling" />
			<NavLinkItem icon={FaBed} to="accommodation" />
			<NavLinkItem icon={MdRestaurant} to="food" />
			<NavLinkItem icon={MdCalendarMonth} to="events" />
			<NavLinkItem icon={MdSearch} to="search" />
			<div className="flex-1"></div>
			{/*<NavItem icon={MdMap} />*/}
		</nav>
	);
};

export default Navigation;
