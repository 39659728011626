import { Poi } from "../interfaces";
import { useIntl } from "react-intl";
import usePoiTranslate from "./usePoiTranslate";

/**
 * usePoiDescription
 */
const usePoiDescription = (poi: Poi) => {
	const intl = useIntl();
	const translate = usePoiTranslate();

	let description = "";
	if (
		poi[`long_description_${intl.locale}`] ||
		!poi[`short_description_${intl.locale}`]
	) {
		description = translate(poi, "long_description");
	}
	if (!description) {
		description = translate(poi, "short_description");
	}

	return description;
};

export default usePoiDescription;
