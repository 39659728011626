import { GeoJSON, geoJSON, Layer, Marker, Map, Icon, icon } from "leaflet";
import "leaflet.markercluster";

import iconImage from "leaflet/dist/images/marker-icon.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconShadowImage from "leaflet/dist/images/marker-shadow.png";
import * as WKT from "terraformer-wkt-parser";

// define leaflet default icon
export const DefaultIcon = icon({
	...Icon.Default.prototype.options,
	iconUrl: iconImage,
	iconRetinaUrl: iconRetinaUrl,
	shadowUrl: iconShadowImage,
});
Marker.prototype.options.icon = DefaultIcon;

/**
 * thx https://github.com/ebrelsford/Leaflet.snogylop/blob/master/src/leaflet.snogylop.js
 *
 * @param geojson
 * @param map
 */
export const invertedGeoJSON = (geojson: any, map: Map): GeoJSON => {
	const worldLatlngs = [
		[0, 90],
		[180, 90],
		[180, -90],
		[0, -90],
		[-180, -90],
		[-180, 0],
		[-180, 90],
		[0, 90],
	];

	//	// Polygon
	//  const coordinates = [];
	//	coordinates.push(worldLatlngs);
	//	coordinates.push(geojson.geometry.coordinates[0]);

	// MultiPolygon
	const coordinates = [[]];
	coordinates[0].push(worldLatlngs);
	for (const l in geojson.geometry.coordinates) {
		coordinates[0].push(geojson.geometry.coordinates[l][0]);
	}

	const inverted = {
		...geojson,
		geometry: {
			...geojson.geometry,
			coordinates,
		},
	};

	const getStyle = (zoom) => {
		// Quelle fonction linéaire pour que f(x <= 8) = 1 et f(x >= 12) = 0 ?
		const r = Math.max(Math.min(-(1 / 4) * zoom + 3, 1), 0);

		return {
			fillColor: "white",
			weight: 2,
			opacity: r * 0.6,
			color: "black",
			fillOpacity: r * 0.6,
			interactive: false,
		};
	};

	const layer = geoJSON(inverted, { style: getStyle(map.getZoom()) });

	map.on("zoomend", (t) => {
		layer.setStyle(getStyle(map.getZoom()));
	});

	return layer;
};

/**
 * Return VectorSource based on WFS layer
 * @param language
 * @param filters
 * @param limit
 * @param sortBy
 * @param geoJSONOptions
 */
export const getWFSLayer = async (
	language: string,
	filters: string[],
	limit?: number,
	sortBy?: string,
	geoJSONOptions?: any
): Promise<Layer> => {
	// eslint-disable-next-line react-hooks/rules-of-hooks

	const properties = [
		"id",
		"sit_id",
		"sit_identifier",
		"type",
		"subtype",
		`name_fr`,
		`name_en`,
		`name_de`,
		"geom",
		`short_description_${language}`,
		"media_link",
		`media_title_${language}`,
		"address_city",
		"tour_distance",
		"event_end_date",
		"event_start_date",
		"tags",
	];

	// filter by language
	filters.push(
		`(name_${language} IS NOT NULL OR short_description_${language} IS NOT NULL)`
	);

	const url = new URL(process.env.REACT_APP_MOSL_WFS_URL);
	url.searchParams.append("service", "WFS");
	url.searchParams.append("version", "2.0.0");
	url.searchParams.append("request", "GetFeature");
	url.searchParams.append("outputFormat", "application/json");
	url.searchParams.append("typename", `mlsf:poi`);
	url.searchParams.append("propertyname", properties.join(","));
	if (limit) {
		url.searchParams.append("count", limit.toString());
	}
	if (sortBy) {
		url.searchParams.append("sortBy", sortBy.toString());
	}
	if (filters.length) {
		url.searchParams.append("CQL_FILTER", `(${filters.join(") AND (")})`);
	}

	// todo : bbox ??
	return fetch(url.toString())
		.then((res) => res.json())
		.then((data) => {
			return geoJSON(data, { ...(geoJSONOptions ? geoJSONOptions : {}) });
		});
};

/**
 * Return VectorSource based on given WKT path
 *
 * @param path
 */
export const getWKTLayer = (path): GeoJSON => {
	const data = WKT.parse(path);
	return geoJSON(data, {
		style: {
			color: "#0284c7",
			weight: 5,
			opacity: 1,
		},
	});
};

export const mapEventOff = (
	map: Map,
	type: string,
	name: string
): { fn: Function; ctx: any } => {
	// @ts-ignore
	const events = map._events[type];
	for (let i = 0; i < events.length; i++) {
		if (events[i].fn.name === name) {
			map.off(type, events[i].fn, events[i].ctx);
			return events[i];
		}
	}
};
