import React from "react";
import useWFSLayer from "../hooks/useWFSLayer";
import ResultList from "../components/ResultList";

const Featured = () => {
	const { features, loading, setHighlighted } = useWFSLayer({
		cqlFilter: "featured=TRUE",
		autoFit: true,
	});
	return (
		<div className="nav-panel">
			<ResultList
				className="p-3"
				loading={loading}
				features={features}
				setHighlighted={setHighlighted}
			/>
		</div>
	);
};

export default Featured;
