import { useIntl } from "react-intl";
import { Poi } from "../interfaces";

/**
 * useTranslateMessage
 */
const usePoiTranslate = (): ((poi: Poi, property: string) => string) => {
	const intl = useIntl();

	return (poi: Poi, property: string) => {
		if (poi[`${property}_${intl.locale}`]) {
			return poi[`${property}_${intl.locale}`];
		}

		if (intl.locale !== "en" && poi[`${property}_en`]) {
			return poi[`${property}_en`];
		}

		for (const key in poi) {
			if (key.startsWith(`${property}_`)) {
				return poi[key];
			}
		}

		return "";
	};
};

export default usePoiTranslate;
