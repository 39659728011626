import React from "react";
import FilteredNavPanel from "../components/FilteredNavPanel";
import useTranslate from "../hooks/useTranslate";

const Cycling = () => {
	const translate = useTranslate();

	const options = [
		{
			label: translate("cycling.choice.featured", "Great cycling tour"),
			value: "subtype='cycling_tour' AND tags LIKE '%featured_tour%'",
		},
		{
			label: translate("cycling.choice.all", "All cycling tour"),
			value: "subtype='cycling_tour'",
		},
		{
			label: translate("cycling.choice.rent", "Bike renting"),
			value: "tags LIKE '%rent_bike%'",
		},
		{
			label: translate("cycling.choice.charging", "Charging station"),
			value: "tags LIKE '%bike_charging_station%'",
		},
	];

	return <FilteredNavPanel options={options} />;
};

export default Cycling;
