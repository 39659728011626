import React, { InputHTMLAttributes, useEffect, useState } from "react";
import useWFSLayer from "../hooks/useWFSLayer";
import useQueryString from "../hooks/useQueryString";
import ResultList from "../components/ResultList";
import useTranslate from "../hooks/useTranslate";
import { useIntl } from "react-intl";

const InputDate = (props: InputHTMLAttributes<HTMLInputElement>) => {
	return (
		<input
			type="date"
			{...props}
			className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1.5 py-1 ${
				props.className ? props.className : ""
			}`}
		/>
	);
};

const getCqlFilter = (
	fields: string[],
	keywords: string,
	startDate: string,
	endDate: string
) => {
	const filters = [];

	if (keywords && keywords !== "") {
		keywords.split(" ").forEach((keyword) => {
			filters.push(
				fields.map((field) => `${field} ILIKE '%${keyword}%'`).join(" OR ")
			);
		});
	}

	if (startDate) {
		filters.push(`event_start_date >= '${startDate}'`);
	}

	if (endDate) {
		filters.push(`event_start_date <= '${endDate}'`);
	}

	return filters.length > 0 ? `(${filters.join(") AND (")})` : null;
};

const Events = () => {
	const intl = useIntl();
	const translate = useTranslate();
	const [keywords, setKeywords] = useQueryString<string>("q", null);
	const [startDate, setStartDate] = useQueryString<string>(
		"s",
		new Date().toISOString().substring(0, 10)
	);
	const [endDate, setEndDate] = useQueryString<string>("e", "");
	const fields = [
		`name_${intl.locale}`,
		`short_description_${intl.locale}`,
		`long_description_${intl.locale}`,
	];

	const [cqlFilter, setCqlFilter] = useState<string>(
		getCqlFilter(fields, keywords, startDate, endDate)
	);

	const { features, loading, setHighlighted } = useWFSLayer({
		cqlFilter: `type='event'${cqlFilter != null ? ` AND (${cqlFilter})` : ""}`,
		sortBy: "event_start_date A",
		//limit: 500,
	});

	useEffect(() => {
		setCqlFilter(getCqlFilter(fields, keywords, startDate, endDate));
	}, [keywords, startDate, endDate]);

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			setKeywords(event.target.value);
		}
	};

	return (
		<div className="nav-panel flex flex-col">
			<div className="m-3 flex flex-col gap-3">
				<div className="relative">
					<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
						<svg
							className="w-4 h-4 text-gray-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 20 20"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
							/>
						</svg>
					</div>
					<input
						type="text"
						id="input-group-1"
						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-9 p-2.5"
						placeholder={translate("search.placeholder", "Search...")}
						defaultValue={keywords}
						autoFocus={true}
						onKeyDown={handleKeyDown}
					/>
				</div>

				<div className="flex gap-2 items-center">
					{translate("event.date.from", "From")}:
					<InputDate
						onChange={(e) => {
							setStartDate(e.target.value);
						}}
						value={startDate}
					/>
					{translate("event.date.to", "To")}:
					<InputDate
						onChange={(e) => {
							setEndDate(e.target.value);
						}}
						value={endDate}
					/>
				</div>
			</div>
			{/* FEATURES */}

			{(loading || features.length > 0) && (
				<ResultList
					className="p-3 pt-0"
					loading={loading}
					features={features}
					setHighlighted={setHighlighted}
				/>
			)}

			{keywords && !loading && !features.length && (
				<div className="p-3 pt-0">
					Il n'y a aucun résultat pour la recherche <strong>{keywords}</strong>
				</div>
			)}
		</div>
	);
};

export default Events;
