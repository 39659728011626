import React, { useState } from "react";
import PartnersModal from "./Modal/PartnersModal";
import TermsOfUseModal from "./Modal/TermsOfUseModal";
import useTranslate from "../hooks/useTranslate";
import AboutModal from "./Modal/AboutModal";
import ue from "../assets/logos/ue-small.png";
import leader from "../assets/logos/leader-small.png";

const Footer = () => {
	const translate = useTranslate();
	const [modal, setModal] = useState<string | null>(null);
	return (
		<>
			<div className="z-[1002] p-2 bg-neutral-800 text-white flex text-sm justify-center items-center gap-2">
				<div className="flex gap-1 flex-col shrink-0 sm:contents">
					<img height={20} alt="" src={ue} />
					<img height={20} alt="" src={leader} />
				</div>
				<div>
					<span>
						L'Europe investit dans les zones rurales. Hier investiert Europa in
						ländlichen Gebiet
					</span>{" "}
					-{" "}
					<button
						className="cursor-pointer underline hover:no-underline"
						onClick={() => setModal("about")}
					>
						{translate("about_us", "About us")}
					</button>{" "}
					-{" "}
					<button
						className="cursor-pointer underline hover:no-underline"
						onClick={() => setModal("partners")}
					>
						{translate("partners", "Partners")}
					</button>{" "}
					-{" "}
					<button
						className="cursor-pointer underline hover:no-underline"
						onClick={() => setModal("legals")}
					>
						{translate("terms_of_use", "Terms of use")}
					</button>
				</div>
			</div>
			<AboutModal open={modal === "about"} onClose={() => setModal(null)} />
			<PartnersModal
				open={modal === "partners"}
				onClose={() => setModal(null)}
			/>
			<TermsOfUseModal
				open={modal === "legals"}
				onClose={() => setModal(null)}
			/>
		</>
	);
};

export default Footer;
