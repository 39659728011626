import usePathNavigate from "./usePathNavigate";
import { Poi } from "../interfaces";

/**
 * This hook return a function to navigate to a given POI, in the current context
 */
const usePoiNavigate = () => {
	const navigate = usePathNavigate();

	return (poi?: Poi) => {
		const { pathname } = window.location;
		const base = pathname.replace(/\/\d+$/i, "");
		navigate(`${base}${poi ? `/${poi.id}` : ""}`);
	};
};

export default usePoiNavigate;
