import Modal, { ModalProps } from "../Modal";
import React from "react";
import useTranslate from "../../hooks/useTranslate";

import { useIntl } from "react-intl";

const PartnersModal = (props: ModalProps) => {
	const intl = useIntl();
	const translate = useTranslate();
	return (
		<Modal {...props} title={translate("about_us", "About us")}>
			{intl.locale === "en" && (
				<p>
					For the first time, you have the possibility to discover the entire
					Mosel valley without borders! Tourist information’s from France,
					Luxembourg and Germany - form the source to the mouth of the river –
					are working together and combine their data to be abled to offer this
					map. This result would not have been possible without the financial
					aid from LEADER.
				</p>
			)}
			{intl.locale === "fr" && (
				<p>
					Vous avez pour la première fois la possibilité de découvrir la
					totalité de la vallée de la Moselle sans aucune frontière ! Les
					offices de tourismes de France, du Luxembourg et d’Allemagne – de la
					source à son embouchure – travaillent ensemble et mettent en commun
					leur données afin de pouvoir proposer cette carte. Ce résultat
					n’aurait pas été possible sans financement LEADER.
				</p>
			)}
			{intl.locale === "de" && (
				<p>
					Zum ersten Mal haben Sie die Möglichkeit, die Gesamtheit des
					internationalen Moseltals grenzenlos zu erkunden! Touristiker aus
					Deutschland, Luxemburg und Frankreich – von der Quelle bis zur Mündung
					- arbeiten gemeinsam und bündeln ihre Daten, um diese Karte anbieten
					zu können. Dieses Ergebnis wäre ohne LEADER-Förderung nicht möglich
					gewesen.
				</p>
			)}
		</Modal>
	);
};

export default PartnersModal;
