import { Poi } from "../interfaces";
import React, { useEffect, useState } from "react";
import { stripHtml } from "string-strip-html";
import NavigationDrawer from "./NavigationDrawer";
import usePoiParamConverter from "../hooks/usePoiParamConverter";
import usePoiNavigate from "../hooks/usePoiNavigate";
import { BiImage } from "react-icons/bi";
import { useSetAtom } from "jotai";
import { poiAtom } from "../App";
import PerfectScrollbar from "react-perfect-scrollbar";
import usePoiTranslate from "../hooks/usePoiTranslate";
import useTranslate from "../hooks/useTranslate";
import { DescriptionList, DescriptionListItem } from "./DescriptionList";
import usePoiDescription from "../hooks/usePoiDescription";
import { AiOutlineHome, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { dateFormat, durationFormat, numberFormat } from "../utils/helpers";
import ReactCountryFlag from "react-country-flag";
import usePoiDescriptors from "../hooks/usePoiDescriptors";
import { FormattedMessage } from "react-intl";

const DrawerContent = ({ poi }: { poi: Poi }) => {
	const [imageError, setImageError] = useState<boolean>(false);
	const translate = usePoiTranslate();
	const trans = useTranslate();
	const descriptors = usePoiDescriptors()(poi);

	const image = poi.media_link;
	const label = translate(poi, "name");

	const description = usePoiDescription(poi);
	const credit = [poi.media_credit, poi.media_license]
		.filter((x) => x)
		.join(" - ");

	return (
		<div className="flex flex-col h-full">
			<div className="relative shadow-lg">
				{image && (
					<div className="image-wrapper group">
						<img
							className="flex-shrink-0 object-cover h-56 w-full"
							src={image}
							alt={translate(poi, "media_title")}
							onError={({ target }: any) => {
								setImageError(true);
								target.remove();
							}}
						/>
						{credit && (
							<div className="p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-100 bg-black text-white text-xs bg-opacity-50 absolute left-0 right-0 bottom-0">
								{credit}
							</div>
						)}
					</div>
				)}

				{(!image || imageError) && (
					<div className="flex flex-col flex-shrink-0 items-center justify-center h-56 w-full bg-gray-200">
						<BiImage className="h-10 w-10 text-gray-400 mb-2" />
						<div className="text-sm text-gray-400">
							{trans("error.image", "no picture")}
						</div>
					</div>
				)}
				<ReactCountryFlag
					countryCode={descriptors.countryCode}
					svg
					style={{ width: "1.5em", height: "auto" }}
					className="absolute bottom-2 right-2 border-white border shadow-sm"
				/>
			</div>

			<PerfectScrollbar className="p-3">
				<div className="text-xl mb-2">{label}</div>

				{/*{types.map((type) => (
					<span
						key={type}
						className="text-sm leading-none inline-block py-1 px-2 rounded text-slate-600 bg-slate-200 last:mr-0 mr-1"
					>
						{type}
					</span>
				))}*/}
				{description && (
					<div className="my-2">{stripHtml(description).result}</div>
				)}
				<DescriptionList>
					<DescriptionListItem label={trans("drawer.address", "Address")}>
						{poi.address_street && <div>{poi.address_street}</div>}
						{poi.address_city && (
							<div>
								{`${poi.address_postal_code || ""} ${
									poi.address_city || ""
								}`.trim()}
							</div>
						)}
						{poi.address_country && <div>{poi.address_country}</div>}
					</DescriptionListItem>

					<DescriptionListItem label={trans("drawer.contact", "Contact")}>
						{poi.homepage && (
							<div>
								<a
									className="inline-block flex items-center gap-1 underline"
									href={poi.homepage}
									target="_blank"
									rel="noreferrer"
								>
									<div className="w-4">
										<AiOutlineHome />
									</div>
									<span className="truncate">{poi.homepage}</span>
								</a>
							</div>
						)}
						{poi.phone_number && (
							<div className="flex items-center gap-1">
								<div className="w-4">
									<AiOutlinePhone />
								</div>
								{poi.phone_number}
							</div>
						)}
						{poi.email && (
							<div>
								<a
									className="inline-block flex items-center gap-1 underline"
									href={`mailto:${poi.email}`}
									target="_blank"
									rel="noreferrer"
								>
									<div className="w-4">
										<AiOutlineMail />
									</div>
									<span className="truncate">{poi.email}</span>
								</a>
							</div>
						)}
					</DescriptionListItem>

					{poi.type === "tour" && (
						<>
							<DescriptionListItem label={trans("drawer.distance", "Distance")}>
								{poi.tour_distance &&
									`${numberFormat(Math.round(poi.tour_distance / 1000))}km`}
							</DescriptionListItem>
							<DescriptionListItem label={trans("drawer.duration", "Duration")}>
								{poi.tour_duration && durationFormat(poi.tour_duration)}
							</DescriptionListItem>
							<DescriptionListItem
								label={trans("drawer.min_altitude", "Minimum altitude")}
							>
								{poi.tour_min_altitude && durationFormat(poi.tour_min_altitude)}
							</DescriptionListItem>
						</>
					)}

					{poi.type === "event" && (
						<>
							<DescriptionListItem label={trans("drawer.event_start", "Start")}>
								{poi.event_start_date &&
									`${dateFormat(poi.event_start_date)}${
										poi.event_start_time
											? ` - ${poi.event_start_time.substring(11, 19)}`
											: ""
									}`}
							</DescriptionListItem>
							<DescriptionListItem label={trans("drawer.event_end", "End")}>
								{poi.event_end_date && dateFormat(poi.event_end_date)}
							</DescriptionListItem>
						</>
					)}
				</DescriptionList>

				{poi.sit_id === 1 && poi.has_been_created_by && (
					<div className="text-xs text-gray-400 mt-2">
						<FormattedMessage
							id="drawer.datatourisme_legal"
							description="Greeting to welcome the user to the app"
							defaultMessage="Data provided by {hasBeenCreatedBy} – {hasBeenPublishedBy}, via DATAtourisme. Last update on {lastUpdate}."
							values={{
								hasBeenCreatedBy: poi.has_been_created_by,
								hasBeenPublishedBy: poi.has_been_published_by,
								lastUpdate: dateFormat(poi.last_update),
							}}
						/>
					</div>
				)}
			</PerfectScrollbar>
		</div>
	);
};

const PoiDrawer = () => {
	const [loading, poi] = usePoiParamConverter();
	const navigate = usePoiNavigate();
	const setPoi = useSetAtom(poiAtom);

	useEffect(() => {
		setPoi(poi);
	}, [poi]);

	return (
		<NavigationDrawer open={loading || poi !== null} onClose={() => navigate()}>
			{/*{loading && <Loading />}*/}
			{!loading && poi && <DrawerContent poi={poi} />}
		</NavigationDrawer>
	);
};

export default PoiDrawer;
