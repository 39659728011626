import * as L from "leaflet";
import { IconType } from "react-icons";
import ReactDOMServer from "react-dom/server";
import iconShadowImage from "leaflet/dist/images/marker-shadow.png";
import { Icon } from "leaflet";

import iconUrl from "../assets/marker-icon-empty.png";
// import iconRetinaUrl from "../assets/marker-icon-empty-2x.png";

class LeafletReactIcon extends L.Icon {
	icon: IconType;

	constructor(icon: IconType, options?) {
		super({
			...Icon.Default.prototype.options,
			glyphMargins: [4, 4], // x, y
			className: "",
			iconUrl,
			iconRetinaUrl: iconUrl, // @see #27 - R1352 - Bug d'affichage des icones de POI
			shadowUrl: iconShadowImage,
			...(options ? options : {}),
		});
		this.icon = icon;
	}

	createIcon() {
		const div = document.createElement("div"),
			options = this.options;
		div.appendChild(this._createElement());
		this._setIconStyles(div, options.className);
		return div;
	}

	private _createElement() {
		const Icon = this.icon;

		const span = document.createElement("span");
		span.innerHTML = ReactDOMServer.renderToString(<Icon />);
		const svg = span.firstChild as any;

		svg.style.color = "white";
		svg.style.margin = "auto";
		svg.style.width = `${
			// @ts-ignore
			this.options.iconSize[0] - this.options.glyphMargins[0] * 2
		}px`;
		svg.style.height = `${
			// @ts-ignore
			this.options.iconSize[1] / 2
		}px`;
		// @ts-ignore
		svg.style.marginTop = `${this.options.glyphMargins[1]}px`;

		return svg;
	}

	private _setIconStyles(div: HTMLDivElement, name: string) {
		if (name === "shadow") {
			// @ts-ignore
			return L.Icon.prototype._setIconStyles.call(this, div, name);
		}

		const options = this.options,
			size = L.point(options["iconSize"]);

		let anchor = L.point(options.iconAnchor);

		if (!anchor && size) {
			anchor = size.divideBy(2);
		}

		div.className = "leaflet-marker-icon leaflet-glyph-icon " + name;
		// @ts-ignore
		const src = this._getIconUrl("icon");

		if (src) {
			div.style.backgroundImage = "url('" + src + "')";
		}

		// @ts-ignore
		if (options.bgPos) {
			div.style.backgroundPosition =
				// @ts-ignore
				-options.bgPos.x + "px " + -options.bgPos.y + "px";
		}
		// @ts-ignore
		if (options.bgSize) {
			div.style.backgroundSize =
				// @ts-ignore
				options.bgSize.x + "px " + options.bgSize.y + "px";
		}

		if (anchor) {
			div.style.marginLeft = -anchor.x + "px";
			div.style.marginTop = -anchor.y + "px";
		}

		if (size) {
			div.style.width = size.x + "px";
			div.style.height = size.y + "px";
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (icon, options?) {
	return new LeafletReactIcon(icon, options);
}
