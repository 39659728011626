import React, { useCallback, useEffect, useRef, useState } from "react";
import { useEventListener, useLockedBody } from "usehooks-ts";

export type ModalProps = {
	title?: string;
	open: boolean;
	onClose: (validate: boolean) => void;
	size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
} & React.HTMLAttributes<HTMLDivElement>;

const Modal = (props: ModalProps) => {
	const { title, open, children, onClose, size = "4xl" } = props;
	const [className, setClassName] = useState("");

	useLockedBody(open);

	const close = () => {
		setClassName("pointer-events-none opacity-0");
		setTimeout(() => onClose(false), 100);
	};

	// close on backdrop click
	const handleClick = useCallback((event: React.MouseEvent) => {
		if (event.target !== event.currentTarget) return;
		event.preventDefault();
		event.stopPropagation();
		close();
	}, []);

	// close on echap
	useEventListener("keydown", (event) => {
		if (!open) return;
		if (event.defaultPrevented) return;
		if (event.key !== "Escape") return;
		event.preventDefault();
		event.stopPropagation();
		close();
	});

	// add the overflow-y-auto in a useEffect to avoid width reflow in SSR context
	const overflowRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (overflowRef.current !== null) {
			overflowRef.current.classList.add("overflow-y-auto");
		}
	}, [overflowRef.current]);

	useEffect(() => {
		setTimeout(() =>
			setClassName(open ? "open opacity-100" : "pointer-events-none opacity-0")
		);
	}, [open]);

	if (!open) {
		return <></>;
	}

	return (
		<div className={`fixed inset-0 z-[1200] group duration-200 ${className}`}>
			<div
				className="fixed inset-0 bg-black bg-opacity-50"
				onClick={handleClick}
			/>
			<div ref={overflowRef} className="fixed inset-0 pointer-events-none">
				<div className="flex min-h-full items-center justify-center p-4 text-center">
					<div
						className={`modal w-full max-w-4xl max-w-${size} overflow-hidden rounded bg-white text-left align-middle shadow-xl duration-200 p-6 transform scale-95 group-[.open]:scale-100 group-[.open]:pointer-events-auto`}
					>
						{title && (
							<h1 className="text-primary font-bold text-xl m-0 mb-4">
								{title}
							</h1>
						)}
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;
