import { MapContainer, TileLayer, useMap, useMapEvent } from "react-leaflet";
import {
	Map,
	Control,
	geoJSON,
	LatLngTuple,
	Layer,
	latLngBounds,
	control,
} from "leaflet";
import moslBounds from "../assets/moselle.json";
import { getWKTLayer, invertedGeoJSON, mapEventOff } from "../utils/map";
import { useEffect, useRef } from "react";
import Zoom = Control.Zoom;
import { atom, useAtomValue } from "jotai/index";
import { useSetAtom } from "jotai";
import { poiAtom } from "../App";
import { tileLayer } from "leaflet";
import MiniMap from "leaflet-minimap";
import { useSearchParams } from "react-router-dom";

const center = [49.04, 6.56] as LatLngTuple;
const defaultZoom = 8;

export const mapAtom = atom<Map>(null as Map);

const osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

const MapComponent = () => {
	const map = useMap();
	const [searchParams, setSearchParams] = useSearchParams();
	const setMap = useSetAtom(mapAtom);
	const poi = useAtomValue(poiAtom);

	// prepare layers ref
	const wktLayer = useRef<Layer>();

	/**
	 * setWKTLayer
	 */
	const setWKTLayer = (newLayer?: Layer) => {
		if (wktLayer.current) {
			map.removeLayer(wktLayer.current);
		}
		wktLayer.current = newLayer ? newLayer : null;
	};

	/**
	 * main useEffect
	 */
	useEffect(() => {
		setMap(map);

		// add mosl layer
		invertedGeoJSON(moslBounds, map).addTo(map);

		// zoom control
		new Zoom({ position: "topright" }).addTo(map);

		// minimap
		const osm = tileLayer(osmUrl, {
			minZoom: 0,
			maxZoom: 13,
		});
		new MiniMap(osm, {}).addTo(map);

		// scale
		control
			.scale({
				position: "bottomright",
				imperial: false,
			})
			.addTo(map);

		// center/zoom initialisation
		if (searchParams.has("c")) {
			const center = searchParams.get("c");
			const zoom = searchParams.get("z");
			// set view to
			map.setView(
				center.split(",") as unknown as [number, number],
				zoom ? parseInt(zoom) : defaultZoom
			);
		} else {
			// fit to mosl bounds
			map.fitBounds(geoJSON(moslBounds as any).getBounds(), {
				paddingTopLeft: [48, 0],
				animate: false,
			});
		}

		// update query string
		map.on("moveend", () => {
			const center = map.getCenter();
			const zoom = map.getZoom();
			setSearchParams({
				c: [center.lat, center.lng].join(","),
				z: zoom.toString(),
			});
		});
	}, [map]);

	// on poi select
	useEffect(() => {
		if (!map) {
			return;
		}

		setWKTLayer(null);

		// remove problematic event from marker-cluster
		mapEventOff(map, "zoomend", "_noanimationUnspiderfy");

		let bounds = null;
		if (poi && poi.tour_trace) {
			const layer = getWKTLayer(poi.tour_trace);
			bounds = layer.getBounds();
			layer.addTo(map);
			setWKTLayer(layer);
		} else if (poi) {
			bounds = latLngBounds(
				[poi.latitude, poi.longitude],
				[poi.latitude, poi.longitude]
			);
		}

		if (bounds) {
			map.flyToBounds(bounds, {
				paddingTopLeft: [510, 0],
				maxZoom: 18,
				duration: 0.5,
				animate: true,
			});
		}
	}, [map, poi]);

	return (
		<>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url={osmUrl}
			/>
		</>
	);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	return (
		<MapContainer
			className="w-full h-full"
			center={center}
			zoom={defaultZoom}
			zoomControl={false}
		>
			<MapComponent />
		</MapContainer>
	);
};
