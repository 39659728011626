import { resolvePath, To, useLocation, useNavigate } from "react-router-dom";
import { NavigateOptions } from "react-router/dist/lib/context";

/**
 * Custom useNavigate hook to keep search params
 */
const usePathNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (to: To, options?: NavigateOptions) => {
    const path = resolvePath(to, location.pathname);
    if (!path.search) {
      path.search = window.location.search.substring(1);
    }

    navigate(path, options);
  };
};

export default usePathNavigate;
