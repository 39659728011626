import { useIntl } from "react-intl";

/**
 * useTranslateMessage
 */
const useTranslate = (): ((id: string, _default: string) => string) => {
	const intl = useIntl();

	return (id: string, _default: string) => {
		return intl.formatMessage({ id, defaultMessage: _default });
	};
};

export default useTranslate;
