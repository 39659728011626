import React from "react";
import FilteredNavPanel from "../components/FilteredNavPanel";
import useTranslate from "../hooks/useTranslate";

const Hiking = () => {
	const translate = useTranslate();

	const options = [
		{
			label: translate("hiking.choice.featured", "Great hikes"),
			value: "subtype='walking_tour' AND tags LIKE '%featured_tour%'",
		},
		{
			label: translate("hiking.choice.all", "All hikes"),
			value: "subtype='walking_tour'",
		},
	];

	return <FilteredNavPanel options={options} />;
};

export default Hiking;
