import Modal, { ModalProps } from "../Modal";
import React from "react";
import useTranslate from "../../hooks/useTranslate";

const TermsOfUseModal = (props: ModalProps) => {
	const translate = useTranslate();
	return (
		<Modal {...props} title={translate("terms_of_use", "Terms of use")}>
			<p>
				<strong>Herausgeber der Karte / Editeur de la carte</strong>
			</p>
			<p>Saarschleifenland Touristik GmbH</p>
			<p>
				<strong>
					Verantwortlicher für die Datenverarbeitung für jede Region /
					responsables des données pour chaque région
				</strong>
			</p>
			<p>
				"Verantwortlicher für die Datenverarbeitung" bezeichnet die Stelle, die
				dafür zuständig ist, wie die Daten verarbeitet, übermittelt und
				aufbewahrt werden.
			</p>
			<p>
				"Le responsable des données" détermine l’organisation qui est en charge
				du traitement, stockage et de la transmission des données
			</p>
			<hr />
			<p>
				Folgende Organisationen agieren als Verantwortlicher für die
				Datenverarbeitung in:
			</p>
			<p>
				Voici les organisations qui agissent comme responsable du traitement des
				données au/en :
			</p>
			<p>
				<strong>LUXEMBOURG</strong>
			</p>
			<p>
				Luxembourg for Tourism GIE <br />
				6, rue Antoine de Saint-Exupéry <br />
				L-1432 Luxembourg
			</p>
			<p>
				Postfach 1001 <br />
				L-1010 Luxembourg
			</p>
			<p>
				N° Handelsregister: C124
				<br />
				Umsatzsteuer-Identifikationsnummer: LU28215929
			</p>
			<p>
				<strong>Externes Hosting der Datenbank:</strong>
			</p>
			<p>Luxembourg for Tourism setzt folgenden Hoster ein:</p>
			<p>
				infomax websolutions GmbH <br />
				Aichfeld 2 <br />
				D-83224 Grassau <br />
				Tel: +49 (0) 8641 - 6 99 30 <br />
				mail@infomax-it.de I www.infomax-it.de
			</p>
			<p>
				Die Datenbank (Luxembourg) nutzt zur Darstellung von Informationen und
				Diensten die Inhalte externer Anbieter.
			</p>
			<ul>
				<li>Kartenmaterial: Geoportail </li>
				<li>
					Buchungsmöglichkeiten von Attraktionen: Regiondo, FareHarbor und
					Ingénie
				</li>
				<li>Unterkunftsbuchungstools: Assd, Ctouvert, Booking, HRS, Cubilis</li>
				<li>Veranstaltungen: echo.lu </li>
			</ul>
			<p>
				<a
					href="https://www.outdooractive.com/"
					target="_blank"
					rel="noreferrer"
				>
					Diese Website nutzt Technologie und Inhalte der Outdooractive
					Plattform.
				</a>
			</p>
			<p>
				<strong>FRANCE</strong>
			</p>
			<p>
				Les données fournies via
				<a href="https://www.datatourisme.fr" target="_blank" rel="noreferrer">
					DATAtourisme
				</a>
				sont en OpenData. Il est indiqué sur chaque fiche le fournisseur de la
				donnée ainsi que la date de sa mise à jour.
			</p>
			<p>
				<strong>RHEINLAND-PFALZ</strong>
			</p>

			<p>
				"Die Datensätze aus Rheinland-Pfalz werden technisch bereitgestellt über
				den
				<a href="http://data.rlp-tourismus.de" target="_blank" rel="noreferrer">
					DataHub Rheinland-Pfalz
				</a>
				. Verantwortlich für die Inhalte ist immer der Datenhalter des
				jeweiligen Datensatzes."
			</p>

			<p>
				<strong>SAARLAND</strong>
			</p>
			<p>Tourismus Zentrale des Saarlandes (TZS)</p>
		</Modal>
	);
};

export default TermsOfUseModal;
