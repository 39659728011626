import React, { useCallback } from "react";
import { useEventListener, useLockedBody } from "usehooks-ts";
import { IoMdClose } from "react-icons/io";

export type NavigationDrawerProps = {
	open: boolean;
	onClose: (validate: boolean) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const NavigationDrawer = (props: NavigationDrawerProps) => {
	const { open, children, onClose } = props;
	useLockedBody(open);

	const close = useCallback(() => onClose(false), [onClose]);

	// close on echap
	useEventListener("keydown", (event) => {
		if (event.defaultPrevented) return;
		if (event.key !== "Escape") return;
		event.preventDefault();
		event.stopPropagation();
		close();
	});

	return (
		<div
			className={`absolute bottom-0 top-0 left-0 w-full max-w-[510px] pl-12 bg-white shadow-2xl z-[1001] transition-all duration-300 ${
				open ? "-translate-x-0" : "-translate-x-full"
			}`}
		>
			<div
				className="absolute bg-neutral-800 rounded-full border-2 border-white shadow-md top-2 right-2 cursor-pointer z-10"
				onClick={close}
			>
				{/*right-0 translate-x-1/2*/}
				<IoMdClose className="fill-white w-7 h-7" />
			</div>
			{children}
		</div>
	);
};

export default NavigationDrawer;
