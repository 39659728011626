// Hook
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Poi } from "../interfaces";

/**
 * Transform a route param to a document fetched from server
 *
 * @param indice
 * @param name
 */
const usePoiParamConverter = (name: string = "id"): [boolean, Poi] => {
	const { [name]: id } = useParams();
	const [param, setParam] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			setLoading(true);
			const url = new URL(process.env.REACT_APP_MOSL_WFS_URL);
			url.searchParams.append("service", "WFS");
			url.searchParams.append("version", "2.0.0");
			url.searchParams.append("request", "GetFeature");
			url.searchParams.append("outputFormat", "application/json");
			url.searchParams.append("typename", `mlsf:poi`);
			url.searchParams.append("CQL_FILTER", `id=${id}`);

			fetch(url.toString())
				.then((response) => response.json())
				.then((data) => data.features?.[0]?.properties)
				.then((poi) => {
					setParam(poi ? poi : null);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setParam(null);
		}
	}, [id]);

	return [loading, param];
};

export default usePoiParamConverter;
