import React, { HTMLAttributes, useState } from "react";
import logo from "../assets/logo-small.png";
import ReactCountryFlag from "react-country-flag";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

const LanguageSwitcher = (props: HTMLAttributes<HTMLDivElement>) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [open, setOpen] = useState<boolean>(false);

	const languages = {
		en: { code: "GB", label: "English" },
		fr: { code: "FR", label: "Français" },
		de: { code: "DE", label: "Deutsch" },
	};

	const goto = (lang: string) => {
		const { pathname } = window.location;
		const path = pathname.replace(/^\/\w{2}/i, `/${lang}`);
		navigate(path);
		setOpen(false);
	};

	return (
		<div
			{...props}
			className={`flex items-center gap-1 cursor-pointer ${
				props.className || ""
			}`}
			onClick={(e) => setOpen(!open)}
		>
			<ReactCountryFlag
				countryCode={languages[intl.locale].code}
				svg
				style={{
					width: "1.2em",
					height: "auto",
				}}
			/>

			{languages[intl.locale].label}

			<div
				className={`absolute top-full right-0 z-10 bg-white shadow w-32 ${
					open ? "visible" : "hidden"
				}`}
			>
				<ul className="text-gray-700" aria-labelledby="dropdownDefaultButton">
					{Object.entries(languages)
						.filter((entry) => entry[0] !== intl.locale)
						.map((entry) => {
							const [code, language] = entry;
							return (
								<li key={code}>
									<a
										href="/"
										onClick={(evt) => {
											evt.preventDefault();
											goto(code);
										}}
										className="flex px-4 py-3 hover:bg-gray-100 items-center gap-1"
									>
										<ReactCountryFlag
											countryCode={language.code}
											svg
											style={{
												width: "1.2em",
												height: "auto",
											}}
										/>
										{language.label}
									</a>
								</li>
							);
						})}
				</ul>
			</div>
		</div>
	);
};

const Header = () => {
	return (
		<header className="bg-white z-[1002] shadow-md flex items-center relative">
			<img src={logo} alt="Moselle sans frontière" className="h-10 m-2" />
			<div className="flex-1"></div>
			<LanguageSwitcher className="text-sm my-auto mx-5" />
		</header>
	);
};

export default Header;
