import Loading from "./Loading";
import PoiCard from "./PoiCard";
import { Feature } from "../interfaces";
import PerfectScrollbar, { ScrollBarProps } from "react-perfect-scrollbar";
import React from "react";
import usePoiNavigate from "../hooks/usePoiNavigate";

type ResultListProps = {
	features: Feature[];
	loading: boolean;
	setHighlighted: (feature: Feature) => void;
} & ScrollBarProps;

const ResultList = ({
	loading,
	features,
	setHighlighted,
	...props
}: ResultListProps) => {
	const navigate = usePoiNavigate();

	return (
		<PerfectScrollbar {...props}>
			{/* loading */}
			{loading && <Loading />}

			{/* results */}
			{features &&
				features.slice(0, 99).map((feature) => {
					const poi = feature.properties;
					return (
						<PoiCard
							key={poi.id}
							poi={poi}
							onMouseEnter={() => setHighlighted(feature)}
							onMouseLeave={() => setHighlighted(null)}
							onClick={() => navigate(poi)}
						/>
					);
				})}
		</PerfectScrollbar>
	);
};

export default ResultList;
