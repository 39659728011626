import React, { useEffect, useState } from "react";
import useWFSLayer from "../hooks/useWFSLayer";
import useQueryString from "../hooks/useQueryString";
import ResultList from "../components/ResultList";
import useTranslate from "../hooks/useTranslate";
import { useIntl } from "react-intl";

const Search = () => {
	const translate = useTranslate();
	const intl = useIntl();

	const [keywords, setKeywords] = useQueryString<string>("q", null);
	const [advanced] = useQueryString<string>("a", "0");

	const [cqlFilter, setCqlFilter] = useState<string>();
	const { features, loading, setHighlighted } = useWFSLayer({
		cqlFilter,
		// limit: 500,
		autoFit: true,
	});

	const fields = [
		`name_${intl.locale}`,
		`short_description_${intl.locale}`,
		`long_description_${intl.locale}`,
	];

	useEffect(() => {
		if (!keywords || !keywords.trim()) {
			setCqlFilter(null);
			return;
		}

		const conditions = [];

		if (advanced === "1") {
			conditions.push(
				fields
					.map(
						(field) => `strMatches(${field}, '.*\\b${keywords}\\b.*') = TRUE`
					)
					.join(" OR ")
			);
		} else {
			keywords.split(" ").forEach((keyword) => {
				conditions.push(
					fields.map((field) => `${field} ILIKE '%${keyword}%'`).join(" OR ")
				);
			});
		}

		// limit events to future
		const today = new Date().toISOString().substring(0, 10);
		conditions.push(`type <> 'event' OR event_end_date >= ${today}`);

		setCqlFilter("(" + conditions.join(") AND (") + ")");
	}, [keywords, advanced]);

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			setKeywords(event.target.value.trim());
		}
	};

	return (
		<div className="nav-panel flex flex-col">
			<div className="m-3">
				<div className="relative">
					<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
						<svg
							className="w-4 h-4 text-gray-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 20 20"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
							/>
						</svg>
					</div>
					<input
						type="text"
						id="input-group-1"
						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-9 p-2.5"
						placeholder={translate("search.placeholder", "Search...")}
						defaultValue={keywords}
						autoFocus={true}
						onKeyDown={handleKeyDown}
					/>
				</div>
			</div>
			{/* FEATURES */}

			{(loading || features.length > 0) && (
				<ResultList
					className="p-3 pt-0"
					loading={loading}
					features={features}
					setHighlighted={setHighlighted}
				/>
			)}

			{keywords && !loading && !features.length && (
				<div className="p-3 pt-0">
					Il n'y a aucun résultat pour la recherche <strong>{keywords}</strong>
				</div>
			)}
		</div>
	);
};

export default Search;
