import Modal, { ModalProps } from "../Modal";
import React from "react";
import useTranslate from "../../hooks/useTranslate";

import ue from "../../assets/logos/ue.jpg";
import ueLeader from "../../assets/logos/ue-leader.jpg";
import ge from "../../assets/logos/ge.png";
import gisGr from "../../assets/logos/gis-gr.png";
import mavdr from "../../assets/logos/mavdr.jpg";
import merzigWadern from "../../assets/logos/merzig-wadern.jpg";
import miselerland from "../../assets/logos/miselerland.jpg";
import moto from "../../assets/logos/mfu.jpg";
import saarland from "../../assets/logos/saarland.jpg";
import remiremont from "../../assets/logos/remiremont.jpg";
import petr from "../../assets/logos/petr.jpg";
import fm from "../../assets/logos/fm.jpg";
import viamosel from "../../assets/logos/viamosel.jpg";
import mlflm from "../../assets/logos/mlflm-2.jpg";
import ccb3f from "../../assets/logos/ccb3f.jpg";
import epinal from "../../assets/logos/epinal.jpg";
import lux from "../../assets/logos/lux.jpg";
import pnrl from "../../assets/logos/pnrl.jpg";

const PartnersModal = (props: ModalProps) => {
	const translate = useTranslate();
	return (
		<Modal {...props} title={translate("partners", "Partners")}>
			<div className="grid grid-cols-3 gap-3 place-items-center auto-cols-max">
				<img className="max-h-28" alt="" src={ue} />
				<img className="max-h-28" alt="" src={ueLeader} />
				<img className="max-h-28" alt="" src={ge} />
				<img className="max-h-28" alt="" src={gisGr} />
				<img className="max-h-28" alt="" src={mavdr} />
				<img className="max-h-28" alt="" src={merzigWadern} />
				<img className="max-h-28" alt="" src={miselerland} />
				<img className="max-h-28" alt="" src={moto} />
				<img className="max-h-28" alt="" src={saarland} />
				<img className="max-h-28" alt="" src={remiremont} />
				<img className="max-h-28" alt="" src={petr} />
				<img className="max-h-28" alt="" src={fm} />
				<img className="max-h-28" alt="" src={viamosel} />
				<img className="max-h-28" alt="" src={mlflm} />
				<img className="max-h-28" alt="" src={epinal} />
				<img className="max-h-28" alt="" src={lux} />
				<img className="max-h-28" alt="" src={ccb3f} />
				<img className="max-h-28" alt="" src={pnrl} />
			</div>
		</Modal>
	);
};

export default PartnersModal;
