import { useState, useCallback, useEffect } from "react";
import qs from "query-string";

const setQueryStringWithoutPageReload = (qsValue: any) => {
	const newurl =
		window.location.protocol +
		"//" +
		window.location.host +
		window.location.pathname +
		qsValue;
	window.history.pushState({ path: newurl }, "", newurl);
};

const getQueryStringValue = <T>(
	key: string,
	queryString = window.location.search
): T => {
	const values = qs.parse(queryString);
	return values[key] as unknown as T;
};

const setQueryStringValue = <T>(
	key: any,
	value: T,
	queryString = window.location.search
) => {
	const values = qs.parse(queryString);
	const newQsValue = qs.stringify({
		...values,
		[key]: value,
	});
	setQueryStringWithoutPageReload(`?${newQsValue}`);
};

const useQueryString = <T>(
	key: string,
	defaultValue: T
): [T, (arg: T) => void] => {
	const [value, setValue] = useState<T>(
		getQueryStringValue(key) || defaultValue
	);
	const onSetValue = useCallback(
		(newValue) => {
			setQueryStringValue(key, newValue);
			setValue(newValue);
		},
		[key]
	);

	useEffect(() => {
		const listener = (event: PopStateEvent) => {
			setValue(getQueryStringValue(key) || defaultValue);
		};
		window.addEventListener("popstate", listener);
		return () => window.removeEventListener("popstate", listener);
	}, [defaultValue, key]);

	return [value, onSetValue];
};

export default useQueryString;
