/*
 * This file is part of the DATAtourisme project.
 * 2022
 * @author Conjecto <contact@conjecto.com>
 * SPDX-License-Identifier: GPL-3.0-or-later
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */

import React, { Children, HtmlHTMLAttributes, ReactNode } from "react";

export const DescriptionListItem = ({
	label,
	children,
	className,
}: {
	label: string;
} & HtmlHTMLAttributes<HTMLDivElement>) => {
	const nodes = Children.toArray(children);
	if (nodes.length === 0) {
		return <></>;
	}

	return (
		<div
			className={`bg-gray-100 even:bg-white px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3 ${
				className || ""
			}`}
		>
			<dt className="font-medium text-gray-500">{label}</dt>
			<dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{children}</dd>
		</div>
	);
};

export const DescriptionList = ({ children }: { children: ReactNode }) => {
	return (
		<div className="border-t border-b border-gray-200 text-sm">
			<dl className="mb-0">{children}</dl>
		</div>
	);
};
