import React from "react";
import "./styles/index.scss";

import Header from "./components/Header";
import Map from "./components/Map";
import Navigation from "./components/Navigation";
import {
	createBrowserRouter,
	Navigate,
	Outlet,
	RouterProvider,
	useLocation,
	useOutlet,
	useParams,
} from "react-router-dom";
import { atom } from "jotai";
import { Poi } from "./interfaces";
import PoiDrawer from "./components/PoiDrawer";
import Featured from "./routes/Featured";
import Hiking from "./routes/Hiking";
import Cycling from "./routes/Cycling";
import Search from "./routes/Search";
import Events from "./routes/Events";
import Accommodation from "./routes/Accommodation";
import Food from "./routes/Food";
import { IntlProvider } from "react-intl";

import de from "./translations/de.json";
import fr from "./translations/fr.json";
import { getDefaultLanguage } from "./utils/helpers";
import Footer from "./components/Footer";

const translations = { de, fr };

export const poiAtom = atom<Poi>(null as Poi);

const AppRoot = () => {
	const outlet = useOutlet();
	const params = useParams();
	const location = useLocation();

	// if there is no outlet, redirect to the featured view
	if (outlet == null) {
		const lang =
			params.lang || getDefaultLanguage(Object.keys(translations), "en");
		return <Navigate to={`/${lang}/featured`} replace />;
	}

	// redirect to navigator language if no language
	if (!params.lang || params.lang.length > 2) {
		const lang = getDefaultLanguage(Object.keys(translations), "en");
		return <Navigate to={`/${lang}${location.pathname}`} replace />;
	}

	return (
		<IntlProvider
			locale={params.lang}
			defaultLocale="en"
			messages={translations[params.lang]}
		>
			<div className="flex flex-col h-full">
				<Header />
				<main className="flex-1 relative flex h-full">
					<Navigation />
					<Outlet />
					<PoiDrawer />
					<Map />
				</main>
				<Footer />
			</div>
		</IntlProvider>
	);
};

const router = createBrowserRouter([
	// {
	// 	index: true,
	// 	element: <Navigate to="/fr/featured" replace />,
	// },
	{
		path: "/:lang?",
		element: <AppRoot />,
		children: [
			{
				path: "featured/:id?",
				// element: <View component={Featured} />,
				element: <Featured />,
			},
			{
				path: "hiking/:id?",
				element: <Hiking />,
			},
			{
				path: "cycling/:id?",
				element: <Cycling />,
			},
			{
				path: "search/:id?",
				element: <Search />,
			},
			{
				path: "events/:id?",
				element: <Events />,
			},
			{
				path: "accommodation/:id?",
				element: <Accommodation />,
			},
			{
				path: "food/:id?",
				element: <Food />,
			},
		],
	},
]);

const App = () => {
	return <RouterProvider router={router} />;
};

export default App;
