import { useCallback, useEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import { mapAtom } from "../components/Map";
import { getWFSLayer } from "../utils/map";
import {
	FeatureGroup,
	GeoJSON,
	latLng,
	Layer,
	LeafletEvent,
	marker,
	markerClusterGroup,
} from "leaflet";
import { Feature } from "../interfaces";
import usePoiNavigate from "./usePoiNavigate";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import usePoiDescriptors from "./usePoiDescriptors";
import usePoiTranslate from "./usePoiTranslate";

// @see https://thenounproject.com/browse/icons/term/map-marker/

type UseWFSLayerReturn = {
	features: Feature[];
	loading: boolean;
	setHighlighted: (feature: Feature) => void;
};

const useWFSLayer = (options?: {
	cqlFilter?: string;
	sortBy?: string;
	limit?: number;
	autoFit?: boolean;
}): UseWFSLayerReturn => {
	const intl = useIntl();
	const navigate = usePoiNavigate();
	const map = useAtomValue(mapAtom);
	const params = useParams();
	const currentLayer = useRef<FeatureGroup>();
	const currentOriginalLayer = useRef<FeatureGroup>();
	const poiDescriptors = usePoiDescriptors();
	const translate = usePoiTranslate();

	const [features, setFeatures] = useState<Feature[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	// deprecated
	const [, setHighlighted] = useState<Feature>(null);

	/**
	 * setCurrentLayer
	 */
	const setCurrentLayer = (newLayer?: FeatureGroup) => {
		if (currentLayer.current) {
			map.removeLayer(currentLayer.current);
		}
		currentLayer.current = newLayer ? newLayer : null;
	};

	/**
	 * onLayerLoad
	 */
	const onLayerLoad = (layer: GeoJSON) => {
		// @see https://github.com/Leaflet/Leaflet.markercluster/blob/master/example/geojson.html
		const clusterLayer = markerClusterGroup({
			showCoverageOnHover: false,
			maxClusterRadius: 40,
			// spiderLegPolylineOptions: { opacity: 0 },
		}).addLayer(layer);

		map.addLayer(clusterLayer);
		setCurrentLayer(clusterLayer);

		// do not fit if the current view is a POI detail
		if (options.autoFit && !params.id && clusterLayer.getBounds().isValid()) {
			map.fitBounds(clusterLayer.getBounds(), {
				paddingTopLeft: [368, 0],
			});
		}

		currentOriginalLayer.current = layer;
		setFeatures(layer.getLayers().map((l: any) => l.feature));

		setLoading(false);
	};

	/**
	 * onMoveEnd
	 */
	const onMoveEnd = (evt: LeafletEvent) => {
		// do not filter if the current view is a POI detail
		if (!params.id && currentOriginalLayer.current) {
			const features = currentOriginalLayer.current
				.getLayers()
				.map((l: any) => l.feature)
				.filter((feature) => {
					const [lng, lat] = feature.geometry.coordinates as number[];
					return map.getBounds().contains(latLng({ lat, lng }));
				});
			setFeatures(features);
		}
	};

	const tooltipContent = useCallback(
		(layer: GeoJSON) => {
			const feature = layer.feature as Feature;
			return translate(feature.properties, "name");
		},
		[translate]
	);

	const geoJSONOptions = {
		pointToLayer: useCallback(
			(feature: Feature, latlng) => {
				const { leafletIcon } = poiDescriptors(feature.properties);
				return marker(latlng, { icon: leafletIcon }).bindTooltip(
					tooltipContent
				);
			},
			[poiDescriptors]
		),
		onEachFeature: useCallback(
			(feature: Feature, layer: Layer) => {
				layer.on("click", (e) => {
					navigate(feature.properties);
				});
			},
			[navigate]
		),
	};

	/**
	 * options useEffect
	 */
	useEffect(() => {
		if (!map) {
			return;
		}

		const filters = options?.cqlFilter ? [options?.cqlFilter] : [];

		/*
		if (!filters.length) {
			setCurrentLayer();
			setFeatures([]);
			return;
		}
		*/

		setLoading(true);
		getWFSLayer(
			intl.locale,
			filters,
			options?.limit,
			options?.sortBy,
			geoJSONOptions
		).then(onLayerLoad);
	}, [map, options.cqlFilter, options.limit, options.sortBy, intl.locale]);

	/**
	 * events useEffect
	 */
	useEffect(() => {
		if (!map) {
			return;
		}
		map.on("moveend", onMoveEnd);
		return () => {
			map.off("moveend", onMoveEnd);
		};
	}, [map, params.id]);

	/**
	 * map useEffect
	 */
	useEffect(() => {
		return () => {
			if (currentLayer.current != null) {
				currentLayer.current.remove();
			}
		};
	}, [map]);

	return { features, loading, setHighlighted };
};

export default useWFSLayer;
