import React from "react";
import FilteredNavPanel from "../components/FilteredNavPanel";
import useTranslate from "../hooks/useTranslate";

const Food = () => {
	const translate = useTranslate();

	const options = [
		{
			label: translate("food.choice.restaurants", "Restaurants"),
			value: "type='place' AND subtype='food_establishment'",
		},
		{
			label: translate("food.choice.winery", "Wineries"),
			value: "type='place' AND tags LIKE '%winery%'",
		},
		// { label: "Producteurs / magasins", value: "tags LIKE '%xxxx%'" },
	];

	return <FilteredNavPanel options={options} />;
};

export default Food;
