import { Poi } from "../interfaces";

import {
	MdCalendarMonth,
	MdLocalOffer,
	MdOutlineDirectionsBike,
	MdOutlineHiking,
	MdPlace,
	MdRestaurant,
} from "react-icons/md";
import useTranslate from "./useTranslate";
import { IconType } from "react-icons";
import { useCallback } from "react";
import { FaBed } from "react-icons/fa6";
import { Icon } from "leaflet";
import { DefaultIcon } from "../utils/map";
import leafletReactIcon from "../utils/leafletReactIcon";

type PoiDescriptors = {
	icon: IconType;
	leafletIcon: Icon;
	title?: string;
	countryCode: string;
};

const usePoiDescriptors = (): ((poi: Poi) => PoiDescriptors) => {
	const translate = useTranslate();

	const _defaultDescriptors = (poi: Poi): Partial<PoiDescriptors> => {
		switch (poi.type) {
			case "tour":
				switch (poi.subtype) {
					case "cycling_tour":
						return {
							icon: MdOutlineDirectionsBike,
							title: translate("poi.type.cycling_tour", "Cycling tour"),
						};
					case "walking_tour":
						return {
							icon: MdOutlineHiking,
							title: translate("poi.type.walking_tour", "Walking tour"),
						};
					default:
						return {
							icon: MdOutlineHiking,
							title: translate("poi.type.tour", "Tour"),
						};
				}
			case "event": {
				return {
					icon: MdCalendarMonth,
					title: translate("poi.type.event", "Event"),
				};
			}
			case "place": {
				switch (poi.subtype) {
					case "accommodation":
						return {
							icon: FaBed,
							title: translate("poi.type.accommodation", "Accommodation"),
						};
					case "food_establishment":
						return {
							icon: MdRestaurant,
							title: translate("poi.type.food", "Restauration"),
						};
					default:
						return {
							icon: MdPlace,
							leafletIcon: DefaultIcon,
							title: translate("poi.type.place", "Place"),
						};
				}
			}
			case "product": {
				return {
					icon: MdLocalOffer,
					title: translate("poi.type.product", "Product"),
				};
			}
		}

		return { icon: null, leafletIcon: DefaultIcon, title: null };
	};

	return useCallback(
		(poi: Poi) => {
			const desc = _defaultDescriptors(poi);

			// if (poi.tags && poi.tags.match(/^featured|featured$|,featured,/)) {
			// 	desc.leafletIcon = leafletReactIcon(MdStar);
			// }

			if (!desc.leafletIcon) {
				desc.leafletIcon = leafletReactIcon(desc.icon);
			}

			desc.countryCode = "EU";
			switch (poi.sit_id) {
				case 1: // Grand Est
					desc.countryCode = "FR";
					break;
				case 2: // Rhénanie-Palatinat
					desc.countryCode = "DE";
					break;
				case 3: // Sarre
					desc.countryCode = "DE";
					break;
				case 4: // Luxembourg
					desc.countryCode = "LU";
					break;
			}

			return desc as PoiDescriptors;
		},
		[translate]
	);
};

export default usePoiDescriptors;
