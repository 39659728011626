import React, { HTMLAttributes, useCallback } from "react";

type CheckboxesProps = {
	options: { label: string; value: string }[];
	value?: string[];
	onChange?: (value: string[]) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "onChange">;

type CheckboxProps = {
	option: { label: string; value: string };
	checked?: boolean;
	onChange?: (checked: boolean) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "onChange">;

const Checkboxes = (props: CheckboxesProps) => {
	const { className, options, value, onChange, ...divProps } = props;

	const onCheckboxChange = useCallback(
		(option: { label: string; value: string }) => (checked) => {
			const update = [...value];
			if (checked) {
				update.push(option.value);
			} else {
				update.splice(update.indexOf(option.value), 1);
			}
			onChange(update);
		},
		[value, onChange]
	);

	return (
		<div className={`flex flex-col gap-2 ${className}`} {...divProps}>
			{options.map((option) => {
				const checked = value.indexOf(option.value) > -1;
				return (
					<Checkbox
						option={option}
						id={option.value}
						key={option.value}
						checked={checked}
						onChange={onCheckboxChange(option)}
					/>
				);
			})}
		</div>
	);
};

const Checkbox = (props: CheckboxProps) => {
	const { option, checked, onChange } = props;
	return (
		<div className="flex items-center">
			<input
				checked={checked}
				id={option.value}
				onChange={(e) => onChange(e.target.checked)}
				type="checkbox"
				value=""
				className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
			/>
			<label
				htmlFor={option.value}
				className="ml-1.5 text-sm font-medium text-gray-900 cursor-pointer"
			>
				{option.label}
			</label>
		</div>
	);
};

export default Checkboxes;
